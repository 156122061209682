.App {
  display: flex;
  margin: 0 auto;
  text-align: center;
  max-width: 600px;
  height: 100vh;
  
}

.App .divImg{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
 }

.App .divImg img{
 width: 50%; 
 margin: 2%;
 
}



.App .divImg section {
  width: 98%;
  height: 10vh;
  background-color: rgb(8, 124, 139);
}

.App .divImg section h4::before{
  content: '';
  margin-right: 5px;
  display: inline-block;
  width: 8px;
  height: 20px;
  background-color: chocolate;
}

.App .divImg .divContato .contatoTitulo h1::before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  width: 8px;
  height: 20px;
  background-color: chocolate;
 
 
}


.App .divImg section h4{
  margin: 3%;
  font-size: 1.5rem;
  text-align: left;
  color: white;
  flex-wrap: wrap;
}

.App .divImg ul li {
  text-align: left;
  font-size: 1.5rem;
  font-family: sans-serif;
  font-style: italic;
  margin-top: 2%;
 }

 
/* li::before{
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #ae81ff;
  border-radius: 50%;
} */

 .App .divImg ul li a{
  text-decoration: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
  
 }

 .App .divImg ul li a small{
  font-size: 16px;
  
 }
 
 
 
 .App .divImg .divProduto{
  height: 100vh;
 }
 
 .App .divImg .divProduto img{
   width: 90%;
 }
 

 .App .divImg .divContato{
   display: flex;
   width: 98%;
   height: 10vh;
   background-color: rgb(8, 124, 139);
   align-items: center;
   justify-content: center;
   flex-direction: row;
  }
  

  .App .divImg .divContato img{
    width: 30%;
   }

   .App .divImg .divContato h1{
   color: white;
   font-size: 1.8rem;
   flex-wrap: nowrap;
   }
   

   .App .divImg .divContato h1 a{
    color: white;
    text-decoration: none;
  
    
    }
    
    .App .divImg .divContato .contatoTitulo{
      display: flex;
      height: 10vh;
      width: 100%;
      margin-left: 2%;
      margin-top: 11%;
      flex-direction: column;
    }
      
    .App .divImg .divContato .contatoImg{
        height: 10vh;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        }
        
        
      
     .divImg .divPersonalizar{
        width: 100%;
        color: rgb(0, 0, 0);
        margin-bottom: 2%;
        
     }

     .divImg .divPersonalizar small{
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        /* font-weight: bolder; */
   }


   .new{
    background-color: rgba(226, 68, 6, 0.712);
    color: #fff;
    border-radius: 2px;
    padding: 2px;
   }



   @media (min-width: 510px) and (max-width: 768px){

    .App .divImg .divProduto{
        margin-bottom: 25%;
     }
     
  
   }
  